import { useState } from 'react'
import { PayPalButtons } from "@paypal/react-paypal-js"
import { PaymentAPI } from '../api/PaymentAPI';

const PayPalCheckoutButton = ({ createOrderId, onPaid, onError, onCancel, disabled }) => {
    return (
        <PayPalButtons
            disabled={disabled}
            fundingSource='paypal'
            style={{
                layout: 'vertical',
                color: 'blue',
                shape: 'pill',
                label: 'paypal'

            }}
            createOrder={async (data, actions) => {
                return createOrderId();
            }}
            onApprove={async (data, actions) => {
                onPaid(data);
            }}
            onCancel={() => {
                onCancel();
            }}
            onError={(err) => {
                onError(err);
            }}
        />
    );

}

export default PayPalCheckoutButton; 