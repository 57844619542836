import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './styles/styles.css';
import Home from './views/Home';
import Privacy from './views/Privacy';
import Cookies from './views/Cookies';
import Packages from './views/Packages';
import Reservation from './views/Reservation';
import TermsAndConditions from './views/TermsAndConditions';
import CookieConsent from "react-cookie-consent";
import ProjectInfo from './views/ProjectInfo';

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route index path="/" element={<Home></Home>} />
          <Route path='/privacy' element={<Privacy />} />
          <Route path={`/cookies`} element={<Cookies />} />
          <Route path={`/packages`} element={<Packages />} />
          <Route path='/projectinfo' element={<ProjectInfo />} />
          <Route path={`/reservation/:packageId?/:spaceType?`} element={<Reservation />} />
          <Route path={`/termsandconditions`} element={<TermsAndConditions />} />
        </Routes>
      </Router>
      <CookieConsent
        buttonText="Accetta"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "white", fontSize: "13px", background: "#b83130" }}>Questo sito utilizza i cookie per migliorare l'esperienza dell'utente.</CookieConsent>
    </>
  );
}

export default App;