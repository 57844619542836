import * as React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Markdown from '../components/Markdown';
import Typography from '../components/Typography';
import AppAppBar from '../modules/AppAppBar';
import AppFooter from '../modules/AppFooter';
import withRoot from '../modules/withRoot';
import projectInfo from './project_info.md';

function ProjectInfo() {
    return (
        <React.Fragment>
            <AppAppBar />
            <Container sx={{ flex: 1 }}>
                <Box sx={{ mt: 7, mb: 12 }}>
                    <Typography variant="h3" gutterBottom marked="center" align="center">
                        Il progetto
                    </Typography>
                    <Markdown>{projectInfo}</Markdown>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <img
                            src={`${process.env.PUBLIC_URL}/assets/images/Immagine1.jpg`}
                            alt="logo"
                            style={{
                                maxWidth: '100%',
                                height: 'auto',
                                padding: 0,
                                margin: 0
                            }}
                        />
                    </Box>
                </Box>
            </Container>
            <AppFooter />
        </React.Fragment>
    );
}

export default withRoot(ProjectInfo);
