import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';
import TextField from '../components/TextField';

function Copyright() {
  return (
    <React.Fragment>
      {'© '}
      <Link color="inherit" href="https://hub.zetaoffice.com">
        ZetaHUB
      </Link>{' '}
      {new Date().getFullYear()}
    </React.Fragment>
  );
}

const iconStyle = {
  width: 48,
  height: 48,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'warning.main',
  mr: 1,
  '&:hover': {
    bgcolor: 'warning.dark',
  },
};



export default function AppFooter() {
  return (
    <Typography
      component="footer"
      sx={{ display: 'flex', bgcolor: 'secondary.light' }}
    >
      <Container sx={{ my: 8, display: 'flex' }}>
        <Grid container spacing={5}>
          <Grid item xs={6} sm={4} md={3}>
            <Grid
              container
              direction="column"
              justifyContent="flex-end"
              spacing={2}
              sx={{ height: 120 }}
            >
              <Grid item sx={{ display: 'flex' }}>
                {/* <Box component="a" href="https://www.facebook.com/zetaofficeufficio" sx={iconStyle}>
                  <img
                    src="/assets/images/facebook.png"
                    alt="Facebook"
                  />
                </Box>
                <Box component="a" href="https://twitter.com/MUI_hq" sx={iconStyle}>
                  <img
                    src="/assets/images/twitter.png"
                    alt="Twitter"
                  />
                </Box> */}
                <Box component="a" href="https://www.instagram.com/zetahub2023" sx={iconStyle}>
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/instagram.png`}
                    alt="Twitter"
                  />
                </Box>
              </Grid>
              <Grid item>
                <Copyright />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={4} md={2}>
            <Typography variant="h6" marked="left" gutterBottom>
              Esplora
            </Typography>
            <Box component="ul" sx={{ m: 0, listStyle: 'none', p: 0 }}>
              {/* <Box component="li" sx={{ py: 0.5 }}>
                <Link href="/premium-themes/onepirate/terms/">Terms</Link>
              </Box> */}
              <Box component="li" sx={{ py: 0.5 }}>
                <Link href={`${process.env.PUBLIC_URL}/projectinfo`}>Il progetto</Link>
              </Box>
              <Box component="li" sx={{ py: 0.5 }}>
                <Link href={`${process.env.PUBLIC_URL}/privacy`}>Informativa sulla privacy</Link>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6} sm={8} md={4}>

          </Grid>
          <Grid item>
            <Typography variant="caption">
              Zeta Office di Martinelli Laura & C. S.A.S - Piazza Repubblica, 5 - 42049 S. Ilario d'Enza (RE) - tel. 0522 472802 - cell 335 1291040 - Partita IVA: 01724140353 - R.E.A. di Reggio Emilia n. 217898 - Pec: info@pec.zetaoffice.com
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Typography>
  );
}
