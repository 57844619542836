import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import PayPalCheckoutButton from '../../components/PayPalCheckoutButton'
import { Box, Button } from '@mui/material';

export default function PaymentForm({ createOrderId, onPaid, onError, onCancel, back, disabled }) {


    return (
        <React.Fragment>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <PayPalCheckoutButton createOrderId={createOrderId} onPaid={onPaid} onCancel={onCancel} onError={onError} disabled={disabled}></PayPalCheckoutButton>
                </Grid>
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    variant="contained"
                    color="secondary"
                    disabled={disabled}
                    onClick={back} sx={{ mt: 3, ml: 1 }}>
                    Indietro
                </Button>
            </Box>
        </React.Fragment>
    );
}