import * as React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';

const item = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  px: 5,
};

function ProductValues() {
  return (
    <Box
      component="section"
      sx={{ display: 'flex', overflow: 'hidden', bgcolor: 'secondary.light' }}
    >
      <Container sx={{ mt: 15, mb: 30, display: 'flex', position: 'relative' }}>
        <Box
          component="img"
          src={`${process.env.PUBLIC_URL}/assets/images/productCurvyLines.png`}
          alt="curvy lines"
          sx={{ pointerEvents: 'none', position: 'absolute', top: -180 }}
        />
        <Grid container spacing={5}>
          <Grid item xs={6} md={3}>
            <Box sx={item}>
              <Box
                component="img"
                src={`${process.env.PUBLIC_URL}/assets/images/svg/print.svg`}
                alt="suitcase"
                sx={{ height: 55 }}
              />
              <Typography variant="h6" sx={{ my: 5 }}>
                Stampe
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} md={3}>
            <Box sx={item}>
              <Box
                component="img"
                src={`${process.env.PUBLIC_URL}/assets/images/svg/sparkles.svg`}
                alt="graph"
                sx={{ height: 55 }}
              />
              <Typography variant="h6" sx={{ my: 5 }}>
                Pulizie
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} md={3}>
            <Box sx={item}>
              <Box
                component="img"
                src={`${process.env.PUBLIC_URL}/assets/images/svg/mailbox.svg`}
                alt="clock"
                sx={{ height: 55 }}
              />
              <Typography variant="h6" sx={{ my: 5 }}>
                Servizio pacchi
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} md={3}>
            <Box sx={item}>
              <Box
                component="img"
                src={`${process.env.PUBLIC_URL}/assets/images/svg/wifi.svg`}
                alt="clock"
                sx={{ height: 55 }}
              />
              <Typography variant="h6" sx={{ my: 5 }}>
                Wifi
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} md={3}>
            <Box sx={item}>
              <Box
                component="img"
                src={`${process.env.PUBLIC_URL}/assets/images/svg/pot-food.svg`}
                alt="clock"
                sx={{ height: 55 }}
              />
              <Typography variant="h6" sx={{ my: 5 }}>
                Convenzioni pranzo/colazione
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} md={3}>
            <Box sx={item}>
              <Box
                component="img"
                src={`${process.env.PUBLIC_URL}/assets/images/svg/chair-office.svg`}
                alt="clock"
                sx={{ height: 55 }}
              />
              <Typography variant="h6" sx={{ my: 5 }}>
                Scontistica su arredo ufficio
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} md={3}>
            <Box sx={item}>
              <Box
                component="img"
                src={`${process.env.PUBLIC_URL}/assets/images/svg/phone.svg`}
                alt="clock"
                sx={{ height: 55 }}
              />
              <Typography variant="h6" sx={{ my: 5 }}>
                Area call
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} md={3}>
            <Box sx={item}>
              <Box
                component="img"
                src={`${process.env.PUBLIC_URL}/assets/images/svg/mug-hot.svg`}
                alt="clock"
                sx={{ height: 55 }}
              />
              <Typography variant="h6" sx={{ my: 5 }}>
                Zona break
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} md={3}>
            <Box sx={item}>
              <Box
                component="img"
                src={`${process.env.PUBLIC_URL}/assets/images/svg/tv.svg`}
                alt="clock"
                sx={{ height: 55 }}
              />
              <Typography variant="h6" sx={{ my: 5 }}>
                Sala riunioni attrezzata
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} md={3}>
            <Box sx={item}>
              <Box
                component="img"
                src={`${process.env.PUBLIC_URL}/assets/images/svg/champagne-glasses.svg`}
                alt="clock"
                sx={{ height: 55 }}
              />
              <Typography variant="h6" sx={{ my: 5 }}>
                Spazio eventi
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} md={3}>
            <Box sx={item}>
              <Box
                component="img"
                src={`${process.env.PUBLIC_URL}/assets/images/svg/sunglasses.svg`}
                alt="clock"
                sx={{ height: 55 }}
              />
              <Typography variant="h6" sx={{ my: 5 }}>
                Giardino e terrazzo relax
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default ProductValues;
