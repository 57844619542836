import * as React from 'react';
import Box from '@mui/material/Box';
import LinkMui from '@mui/material/Link';
import { Link } from 'react-router-dom';
import AppBar from '../components/AppBar';
import Toolbar from '../components/Toolbar';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import useMediaQuery from '@mui/material/useMediaQuery';

const rightLink = {
  fontSize: 16,
  color: 'common.white',
  ml: 3,
};

function AppAppBar({ dynamic }) {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  const matches = useMediaQuery('(max-width:600px)');

  return (
    <>
      <AppBar position="fixed" dynamic={dynamic}>
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Box sx={{ flex: 1 }} >
            <Link to={`/`}>
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/LOGOZhub.svg`}
                alt="logo"
                height={dynamic && !trigger && !matches ? '120' : '70'}
                style={{ position: 'absolute', top: 0 }}
              />
            </Link>
          </Box>
          <Box sx={{ flex: 1 }} ></Box>
          <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end', gap: 5 }}>
            <LinkMui
              color="inherit"
              variant="h6"
              underline="none"
              href={`${process.env.PUBLIC_URL}/packages`}
              sx={rightLink}
            >
              {'Prenota'}
            </LinkMui>
            {/* <LinkMui
              color="inherit"
              variant="h6"
              underline="none"
              href={`${process.env.PUBLIC_URL}/reservation`}
              sx={{ ...rightLink }}
            >
              {'Prenota'}
            </LinkMui> */}
          </Box>
        </Toolbar>
      </AppBar>
      {!dynamic && <Toolbar />}
    </>
  );
}

export default AppAppBar;
