import * as React from 'react';
import Button from '../components/Button';
import Typography from '../components/Typography';
import ProductHeroLayout from './ProductHeroLayout';

// const backgroundImage =
//   'https://images.unsplash.com/photo-1534854638093-bada1813ca19?auto=format&fit=crop&w=1400';

const backgroundImage =
  `${process.env.PUBLIC_URL}/assets/images/foto di copertina.jpg`;

export default function ProductHero() {
  return (
    <ProductHeroLayout
      sxBackground={{
        backgroundImage: `url('${backgroundImage}')`,
        backgroundColor: '#7fc7d9', // Average color of the background image.
        backgroundPosition: 'center',
      }}
    >
      {/* Increase the network loading priority of the background image. */}
      <img
        style={{ display: 'none' }}
        src={backgroundImage}
        alt="increase priority"
      />
      <Typography color="inherit" align="center" variant="h2" marked="center">
        Benvenuti in ZetaHUB!
      </Typography>
      <Typography
        color="inherit"
        align="center"
        variant="h5"
        sx={{ mb: 4, mt: { xs: 4, sm: 10 } }}
      >
        Scopri le nostre tariffe
      </Typography>
      <Button
        color="secondary"
        variant="contained"
        size="large"
        component="a"
        href={`${process.env.PUBLIC_URL}/packages`}
        sx={{ minWidth: 200 }}
      >
        Tariffe
      </Button>
      <Typography variant="body2" color="inherit" sx={{ mt: 2 }}>
        “Mettersi insieme è un inizio, rimanere insieme è un progresso, lavorare insieme un successo.” - Henry Ford
      </Typography>
    </ProductHeroLayout>
  );
}
