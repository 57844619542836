import { api } from "./config/axiosConfig"
import { defineCancelApiObject } from "./config/axiosUtils"

export const ReservationAPI = {
    create: async function (request, cancel = false) {
        const response = await api.request({
            url: `api/reservation/create`,
            method: "POST",
            data: request,

            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    disable: async function (request, cancel = false) {
        const response = await api.request({
            url: `api/reservation/disable`,
            method: "POST",
            data: request,

            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
}

// defining the cancel API object for SpaceAPI
const cancelApiObject = defineCancelApiObject(ReservationAPI) 