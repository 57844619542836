import { api } from "./config/axiosConfig"
import { defineCancelApiObject } from "./config/axiosUtils"

export const PaymentAPI = {
    create: async function (request, cancel = false) {
        const response = await api.request({
            url: `api/payment/create`,
            method: "POST",
            data: request,

            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    confirm: async function (request, cancel = false) {
        const response = await api.request({
            url: `api/payment/confirm`,
            method: "POST",
            data: request,

            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    cancel: async function (request, cancel = false) {
        const response = await api.request({
            url: `api/payment/cancel`,
            method: "POST",
            data: request,

            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
}

const cancelApiObject = defineCancelApiObject(PaymentAPI)