import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';
import TextField from '../components/TextField';
import Button from '../components/Button';

function Spaces() {
  return (
    <Container component="section" sx={{ mt: 10, display: 'flex', flexDirection: 'column', gap: 10 }}>
      <Grid container>
        <Grid
          item
          xs={12}
          md={6}
          order={{ xs: 2, md: 1 }}
          sx={{ display: { md: 'block' }, position: 'relative' }}
        >
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/verde_menta.jpg`}
            alt="logo"
            style={{
              maxWidth: '100%',
              height: 'auto',
              padding: 0,
              margin: 0
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              py: 8,
              px: 3
            }}
          >
            <Box sx={{ maxWidth: 400 }}>
              <Typography variant="h3" component="h3" gutterBottom>
                Verde menta
              </Typography>
              <Typography variant="h5">
                Sono postazioni in openspace dotate di schermi fonoassorbenti per una corretta privacy tra gli utilizzatori.
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Grid container>
        <Grid
          item
          xs={12}
          md={6}
          order={{ xs: 2, md: 2 }}
          sx={{ display: { md: 'block' }, position: 'relative' }}
        >
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/taupe.jpg`}
            alt="logo"
            style={{
              maxWidth: '100%',
              height: 'auto',
              padding: 0,
              margin: 0
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} order={{ xs: 1, md: 1 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              py: 8,
              px: 3
            }}
          >
            <Box sx={{ maxWidth: 400 }}>
              <Typography variant="h3" component="h3" gutterBottom>
                Taupe
              </Typography>
              <Typography variant="h5">
                Sono postazioni in open space dotate di tutte le tecnologie del mondo ufficio.
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Grid container>
        <Grid
          item
          xs={12}
          md={6}
          order={{ xs: 2, md: 1 }}
          sx={{ display: { md: 'block' }, position: 'relative' }}
        >
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/viva magenta.jpg`}
            alt="logo"
            style={{
              maxWidth: '100%',
              height: 'auto',
              padding: 0,
              margin: 0
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              py: 8,
              px: 3
            }}
          >
            <Box sx={{ maxWidth: 400 }}>
              <Typography variant="h3" component="h3" gutterBottom>
                Viva Magenta
              </Typography>
              <Typography variant="h5">
                Sono le postazioni più riservate all'interno di una stanza isolata a ridosso della zona ristoro e della sala riunioni.
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Grid container>
        <Grid
          item
          xs={12}
          md={6}
          order={{ xs: 2, md: 2 }}
          sx={{ display: { md: 'block' }, position: 'relative' }}
        >
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/sala_rosa_cipria.jpg`}
            alt="logo"
            style={{
              maxWidth: '100%',
              height: 'auto',
              padding: 0,
              margin: 0
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} order={{ xs: 1, md: 1 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              py: 8,
              px: 3
            }}
          >
            <Box sx={{ maxWidth: 400 }}>
              <Typography variant="h3" component="h3" gutterBottom>
                sala riunioni Rosa Cipria
              </Typography>
              <Typography variant="h5">
                Sala riunioni per 20 posti intorno al tavolo chiusa da pareti vetrate dotata di schermo per proiezioni. Questa sala può arrivare a 30 posti con disposizione a platea.
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Grid container>
        <Grid
          item
          xs={12}
          md={6}
          order={{ xs: 2, md: 1 }}
          sx={{ display: { md: 'block' }, position: 'relative' }}
        >
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/sala riunioni gray.jpg`}
            alt="logo"
            style={{
              maxWidth: '100%',
              height: 'auto',
              padding: 0,
              margin: 0
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              py: 8,
              px: 3
            }}
          >
            <Box sx={{ maxWidth: 400 }}>
              <Typography variant="h3" component="h3" gutterBottom>
                sala riunioni  Gray
              </Typography>
              <Typography variant="h5">
                Sala riunioni per 8/10 posti chiusa da pareti vetrate dotata di schermo  per proiezioni
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Spaces;
