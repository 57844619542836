import * as React from 'react';
import AppFooter from '../modules/AppFooter';
import ProductHero from '../modules/ProductHero';
import ProductValues from '../modules/ProductValues';
import ProductHowItWorks from '../modules/ProductHowItWorks';
import AppAppBar from '../modules/AppAppBar';
import withRoot from '../modules/withRoot';
import Spaces from '../modules/Spaces'


function Index() {
    return (
        <>
            <AppAppBar dynamic='true' />
            <ProductHero />
            <ProductValues />
            {/* <ProductCategories /> */}
            <Spaces />
            <ProductHowItWorks />
            <AppFooter />

        </>
    );
}

export default withRoot(Index);
