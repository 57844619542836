import { api } from "./config/axiosConfig"
import { defineCancelApiObject } from "./config/axiosUtils"

export const PackageAPI = {
    getAll: async function (cancel = false) {
        const response = await api.request({
            url: "/api/package/getall",
            method: "GET",
            signal: cancel ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    }
}

// defining the cancel API object for PackageAPI
const cancelApiObject = defineCancelApiObject(PackageAPI)