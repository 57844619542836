import * as React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { Card, CardHeader, CardContent, CardActions } from '@mui/material';
import { Button, Paper } from '@mui/material';
import Box from '@mui/material/Box';
import Markdown from '../components/Markdown';
import Typography from '../components/Typography';
import AppAppBar from '../modules/AppAppBar';
import AppFooter from '../modules/AppFooter';
import withRoot from '../modules/withRoot';
// import privacy from './privacy.md';
import { PackageAPI } from '../api/PackageAPI';
import { useState, useEffect } from 'react';
import Link from '@mui/material/Link';


function Privacy() {
    const [packages, setPackages] = useState([]);

    useEffect(() => {
        if (!packages.length) {
            PackageAPI.getAll().then((data) => {
                setPackages(data);
            });
        }
    }, [])

    return (
        <React.Fragment>
            <AppAppBar />
            <Container sx={{ flex: 1, pb: 10 }}>
                <Box sx={{ mt: 7, mb: 12 }}>
                    <Typography variant="h3" gutterBottom marked="center" align="center">
                        SCRIVANIE
                    </Typography>
                </Box>
                <Grid container spacing={5} alignItems="flex-end">
                    {packages.filter(x => x.SpaceType == 0).sort((a, b) => a.Order - b.Order).map((tier) => (
                        // Enterprise card is full width at sm breakpoint
                        <Grid
                            key={tier.Name}
                            xs={12}
                            sm={tier.Name === 'Enterprise' ? 12 : 6}
                            md={4}
                        >
                            <Link href={`${process.env.PUBLIC_URL}/reservation/${tier.Id}/0`} sx={{ textDecoration: 'none' }}>
                                <Paper elevation={3} sx={{
                                    border: '1px solid #c7c8ca', display: 'flex', flexDirection: 'column', borderRadius: 'unset', padding: '8px', background: 'white',
                                    '&:hover': {
                                        boxShadow: 'unset'
                                    },
                                }}>
                                    <Box sx={{ flex: '1', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: tier.Color, color: 'white', fontSize: '4em', padding: 1, minHeight: '200px' }}>
                                        <Typography component="h2" variant="h1" color="white" align='center'>
                                            {tier.Price}€
                                        </Typography>
                                    </Box>
                                    <Box sx={{ backgroundColor: 'white', minHeight: '90px', padding: 1 }}>
                                        <Typography component="h3" variant="h4" color="text.primary">
                                            {tier.Name}
                                        </Typography>
                                        <Typography component="h4" variant="h5" color="text.primary">
                                            {tier.Description}
                                        </Typography>
                                    </Box>
                                </Paper>
                            </Link>
                        </Grid>
                    ))}
                </Grid>
                <Box sx={{ mt: 7, mb: 12 }}>
                    <Typography variant="h3" gutterBottom marked="center" align="center">
                        SALE RIUNIONI
                    </Typography>
                </Box>
                <Grid container spacing={5} alignItems="flex-end">
                    {packages.filter(x => x.SpaceType == 1).sort((a, b) => a.Order - b.Order).map((tier) => (
                        // Enterprise card is full width at sm breakpoint
                        <Grid
                            key={tier.Name}
                            xs={12}
                            sm={tier.Name === 'Enterprise' ? 12 : 6}
                            md={4}
                        >
                            <Link href={`${process.env.PUBLIC_URL}/reservation/${tier.Id}/1`} sx={{ textDecoration: 'none' }}>
                                <Paper elevation={3} sx={{
                                    border: '1px solid #c7c8ca', display: 'flex', flexDirection: 'column', borderRadius: 'unset', padding: '8px', background: 'white',
                                    '&:hover': {
                                        boxShadow: 'unset'
                                    },
                                }}>
                                    <Box sx={{ flex: '1', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: tier.Color, color: 'white', fontSize: '4em', padding: 1, minHeight: '200px' }}>
                                        <Typography component="h2" variant="h1" color="white" align='center'>
                                            {tier.Price}€
                                        </Typography>
                                    </Box>
                                    <Box sx={{ backgroundColor: 'white', minHeight: '90px', padding: 1 }}>
                                        <Typography component="h3" variant="h4" color="text.primary">
                                            {tier.Name}
                                        </Typography>
                                        <Typography component="h4" variant="h5" color="text.primary">
                                            {tier.Description}
                                        </Typography>
                                    </Box>
                                </Paper>
                            </Link>
                        </Grid>
                    ))}
                </Grid>
            </Container>
            <AppFooter />
        </React.Fragment >
    );
}

export default withRoot(Privacy);
