import * as React from 'react';
import MuiAppBar from '@mui/material/AppBar';
import useScrollTrigger from '@mui/material/useScrollTrigger';

function AppBar(props) {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  const [dynamic, setDynamic] = React.useState(props.dynamic);

  return <MuiAppBar elevation={0} position="fixed" {...props} style={{ background: props.dynamic && !trigger ? 'transparent' : '#00000082' }} />;
}

export default AppBar;
