import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import { MenuItem } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import { PackageAPI } from '../../api/PackageAPI';
import { useState, useEffect } from 'react';
import { useForm } from "react-hook-form"
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import FormHelperText from '@mui/material/FormHelperText'
import moment from 'moment/moment';
import { SettingsInputAntennaTwoTone } from '@mui/icons-material';
import { SpaceAPI } from '../../api/SpaceAPI';


export default function ReservationForm({ packageId, spaceId, fullName, email, phone, date, time, dates, vat, pec, address, updateFields, spaceType, termsAndConditionsAccepted, next }) {
  const [packages, setPackages] = useState([]);
  const [spaces, setSpaces] = useState([]);
  const [currentSpaceType, setCurrentSpaceType] = useState(spaceType || 0);
  const { register, trigger, formState: { errors } } = useForm({
    // mode: "onChange",
  });
  const [hours, setHours] = useState([
    '8:30',
    '9:00', '9:30',
    '10:00', '10:30',
    '11:00', '11:30',
    '12:00', '12:30',
    '13:00', '13:30',
    '14:00', '14:30',
    '15:00', '15:30',
    '16:00', '16:30',
    '17:00', '17:30',
    '18:00', '18:30'])

  useEffect(() => {
    if (!packages.length) {
      PackageAPI.getAll().then((data) => {
        setPackages(data);
      });
    }

    if (!spaces.length) {
      SpaceAPI.getAll().then((data) => {
        setSpaces(data);
      });
    }
  }, [])

  const required = {
    value: true,
    message: 'Campo obbligatorio'
  }

  const maxLength = (maxLength) => {
    return {
      value: maxLength,
      message: `Massimo ${maxLength} caratteri`
    }
  }

  const minLength = (minLength) => {
    return {
      value: minLength,
      message: `Minimo ${minLength} caratteri`
    }
  }

  const handleNext = async () => {
    let isValid = false;
    if (await trigger())
      next();
  }

  const selectedPackage = () => {
    return packages.find(x => x.Id == packageId);
  }

  // const updateDates = (index, value) => {
  //   let newArr = [...dates];
  //   newArr[index] = value;
  //   setDates(newArr);
  //   return newArr;
  // }

  const onChangeDate = (e) => {
    var day = new Date(e.target.value).getUTCDay();
    if ([6, 0].includes(day)) {
      e.preventDefault();
      e.target.value = '';
      alert('Questo giorno Zetahub è chiusa');
    }

    updateFields({ date: e.target.value });
  }

  const onChangeDates = (e, index) => {
    var day = new Date(e.target.value).getUTCDay();
    if ([6, 0].includes(day)) {
      e.preventDefault();
      e.target.value = '';
      alert('Questo giorno Zetahub è chiusa');
    }

    let newArray = [...dates];
    newArray[index] = e.target.value;
    updateFields({ dates: newArray });
  }

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        PRENOTAZIONE
      </Typography>
      <Grid container spacing={3} alignItems={'end'}>
        <Grid item xs={12}>
          <TextField
            select
            label="Tipo spazio"
            fullWidth
            variant="standard"
            autoComplete="off"
            value={currentSpaceType}
            onChange={e => setCurrentSpaceType(e.target.value)}>
            <MenuItem value={0}>Scrivania</MenuItem>
            <MenuItem value={1}>Sala riunioni</MenuItem>
          </TextField>
        </Grid>
        {currentSpaceType == 1 &&
          <Grid item xs={12}>
            <TextField
              select
              required
              label="Sala riunioni"
              fullWidth
              variant="standard"
              autoComplete="off"
              {...register('spaceId', { required })}
              helperText={errors?.spaceId?.message}
              error={errors?.spaceId?.message ? true : false}
              onChange={e => updateFields({ spaceId: e.target.value })}>
              {spaces.filter(x => x.SpaceType == 1).sort((a, b) => (a.Name > b.Name) ? 1 : ((a.Name < b.Name) ? -1 : 0)).map(x => <MenuItem key={x.Id} value={x.Id}>{x.Name} {x.Description && `(${x.Description})`}</MenuItem>)}
            </TextField>
          </Grid>
        }
        <Grid item xs={12}>
          <TextField
            select
            required
            label="Pacchetto"
            id="packageId"
            fullWidth
            variant="standard"
            autoComplete="off"
            value={packageId}
            {...register('packageId', { required })}
            helperText={errors?.packageId?.message}
            error={errors?.packageId?.message ? true : false}
            onChange={e => { updateFields({ packageId: e.target.value }); }}
          >
            {packages.filter(x => x.SpaceType == currentSpaceType).sort((a, b) => a.Order - b.Order).map(x => <MenuItem key={x.Id} value={x.Id}>{x.Name} ({x.Price}€)</MenuItem>)}
          </TextField>
        </Grid>

        {selectedPackage()?.Consecutive == true &&
          <Grid item xs={selectedPackage()?.MeasurementUnit == 0 ? 6 : 12}>
            <InputLabel id="package-label">Data *</InputLabel>
            <Grid item xs>
              <TextField
                required
                id="date"
                name="date"
                type='date'
                fullWidth
                autoComplete="off"
                variant="standard"
                value={date}
                inputProps={{ min: moment().format('YYYY-MM-DD') }}
                {...register('date', { required })}
                helperText={errors?.date?.message}
                error={errors?.date?.message ? true : false}
                onChange={e => onChangeDate(e)}
              />
            </Grid>
          </Grid>
        }
        {selectedPackage()?.MeasurementUnit == 1 && selectedPackage()?.Consecutive == false &&
          Array.from(Array(selectedPackage().Quantity)).map((x, index) =>
            <Grid item xs={12} key={index}>
              <InputLabel id="package-label">Giorno {index + 1} *</InputLabel>
              <Grid item xs>
                <TextField
                  required
                  name="date"
                  type='date'
                  fullWidth
                  autoComplete="off"
                  variant="standard"
                  inputProps={{ min: moment().format('YYYY-MM-DD') }}
                  {...register(`date${index}`, { required })}
                  helperText={errors?.[`date${index}`]?.message}
                  error={errors?.[`date${index}`]?.message ? true : false}
                  onChange={e => onChangeDates(e, index)}
                />
              </Grid>
            </Grid>
          )
        }
        {selectedPackage()?.MeasurementUnit == 0 &&
          <Grid item xs={6} >
            <TextField
              select
              required
              label="Ora"
              id="time"
              fullWidth
              variant="standard"
              autoComplete="off"
              value={time}
              {...register('time', { required })}
              helperText={errors?.time?.message}
              error={errors?.time?.message ? true : false}
              onChange={e => updateFields({ time: e.target.value })}
            >
              {hours.filter((x, i) => i < hours.length - (selectedPackage().Quantity * 2)).map(x => <MenuItem key={x} value={x}>{x}</MenuItem>)}
            </TextField>
          </Grid>
        }
        <Grid item xs={12}>
          <TextField
            required
            id="fullName"
            name="fullName"
            label="Nome e cognome / Ragione sociale"
            fullWidth
            autoComplete="off"
            variant="standard"
            value={fullName}
            inputProps={{ maxLength: 100 }}
            {...register('fullName', { required, maxLength: maxLength(100) })}
            helperText={errors?.fullName?.message}
            error={errors?.fullName?.message ? true : false}
            onChange={e => updateFields({ fullName: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="email"
            name="email"
            label="E-mail"
            type="email"
            fullWidth
            variant="standard"
            autoComplete="off"
            value={email}
            inputProps={{ maxLength: 100 }}
            {...register('email', { required, maxLength: maxLength(100), pattern: { value: /\S+@\S+\.\S+/, message: 'E-mail non corretta' } })}
            helperText={errors?.email?.message}
            error={errors?.email?.message ? true : false}
            onChange={e => updateFields({ email: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="phone"
            name="phone"
            label="Cellulare"
            type="tel"
            fullWidth
            autoComplete="off"
            variant="standard"
            value={phone}
            inputProps={{ maxLength: 15 }}
            {...register('phone', { required, maxLength: maxLength(15), pattern: { value: /^[\(\)\+\ \d\-\.]*$/, message: "Cellulare non corretto" } })}
            helperText={errors?.phone?.message}
            error={errors?.phone?.message ? true : false}
            onChange={e => updateFields({ phone: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="vat"
            name="vat"
            label="Partita IVA"
            fullWidth
            autoComplete="off"
            variant="standard"
            value={vat}
            inputProps={{ maxLength: 11 }}
            {...register('vat', { maxLength: maxLength(11), minLength: minLength(11), pattern: { value: /^\d+$/, message: "Campo numerico" } })}
            helperText={errors?.vat?.message}
            error={errors?.vat?.message ? true : false}
            onChange={e => updateFields({ vat: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="pec"
            name="pec"
            label="Codice destinatario / PEC"
            fullWidth
            autoComplete="off"
            variant="standard"
            value={pec}
            inputProps={{ maxLength: 100 }}
            {...register('pec', { maxLength: maxLength(100) })}
            helperText={errors?.pec?.message}
            error={errors?.pec?.message ? true : false}
            onChange={e => updateFields({ pec: e.target.value })}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="address"
            name="address"
            label="Indirizzo"
            fullWidth
            autoComplete="off"
            variant="standard"
            value={address}
            inputProps={{ maxLength: 300 }}
            {...register('address', { maxLength: maxLength(300) })}
            helperText={errors?.address?.message}
            error={errors?.address?.message ? true : false}
            onChange={e => updateFields({ address: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} style={{ marginTop: "40px" }}>
          <Link href={`${process.env.PUBLIC_URL}/termsAndConditions`} target="_blank">{'Termini e Condizioni'}</Link>
        </Grid>
        <Grid item xs={12}>
          <FormControl
            error={errors?.termsAndConditions?.message ? true : false}>
            <FormControlLabel
              control={<Checkbox color="secondary" id="termsAndConditions" name="termsAndConditions" {...register('termsAndConditions', { required })}
                onClick={e => { updateFields({ termsAndConditionsAccepted: e.target.checked }); }} autoComplete="off" />}
              label="Accetto i termini e condizioni di contratto"
            />
            <FormHelperText>{errors?.termsAndConditions?.message}</FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleNext}
          sx={{ mt: 3, ml: 1 }}>
          Avanti
        </Button>
      </Box>
    </React.Fragment >
  );
}