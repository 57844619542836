import * as React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Markdown from '../components/Markdown';
import Typography from '../components/Typography';
import AppAppBar from '../modules/AppAppBar';
import AppFooter from '../modules/AppFooter';
import withRoot from '../modules/withRoot';
import privacy from './privacy.md';

function Privacy() {
    return (
        <React.Fragment>
            <AppAppBar />
            <Container sx={{ flex: 1 }}>
                <Box sx={{ mt: 7, mb: 12 }}>
                    <Typography variant="h3" gutterBottom marked="center" align="center">
                        Privacy
                    </Typography>
                    <Markdown>{privacy}</Markdown>
                </Box>
            </Container>
            <AppFooter />
        </React.Fragment>
    );
}

export default withRoot(Privacy);
