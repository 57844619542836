import * as React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Markdown from '../components/Markdown';
import Typography from '../components/Typography';
import AppAppBar from '../modules/AppAppBar';
import AppFooter from '../modules/AppFooter';
import withRoot from '../modules/withRoot';
import { useState, useEffect, useRef } from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { SpaceAPI } from '../api/SpaceAPI';
import { ReservationAPI } from '../api/ReservationAPI';
import ReservationForm from '../modules/reservation/ReservationForm';
import PaymentForm from '../modules/reservation/PaymentForm';
import Review from '../modules/reservation/Review';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import Snackbar from '../components/Snackbar';
import useMultistepForm from '../hooks/useMultistepForm';
import { useParams } from "react-router-dom";
import { PaymentAPI } from '../api/PaymentAPI';

const stepsLabels = ['Prenotazione', 'Pagamento', 'Riepilogo'];

const initialReservationData = {
    packageId: '',
    spaceType: 0,
    spaceId: '',
    date: '',
    dates: [],
    time: '',
    fullName: '',
    phone: '',
    email: '',
    vat: '',
    pec: '',
    address: '',
    termsAndConditionsAccepted: false
};

function Reservation() {
    const routeParams = useParams();

    initialReservationData.packageId = routeParams.packageId ?? '';
    initialReservationData.spaceType = routeParams.spaceType ?? '';

    const [validationMessageOpen, setValidationMessageOpen] = useState(false);
    const [validationMessage, setValidationMessage] = useState('');
    const [reservationData, setReservationData] = useState(initialReservationData);
    const [reservationId, setReservationId] = useState(null);
    const [paymentId, setPaymentId] = useState(null);
    const [paymentDisabled, setPaymentDisavled] = useState(false);
    const paymentIdRef = useRef(paymentId);

    const handleNext = async () => {
        if (currentStepIndex == 0) {
            try {
                let reservationId = await ReservationAPI.create(reservationData);
                setReservationId(reservationId);
                next();
            }
            catch (ex) {
                console.error(ex);
                setValidationMessage('Data non disponibile');
                setValidationMessageOpen(true);
            }
        }
    };

    const handlePaymentSuccess = async (data) => {
        await PaymentAPI.confirm({
            orderID: data.orderID,
            ReservationId: reservationId
        });

        next();
    }

    const handlePaymentError = async () => {
        setPaymentDisavled(false);

        setValidationMessage('Errore durante pagamento.');
        setValidationMessageOpen(true);

        await PaymentAPI.cancel({
            PaymentId: paymentIdRef.current
        });
    };

    const handlePaymentCancellation = async () => {
        setPaymentDisavled(false);

        await PaymentAPI.cancel({
            PaymentId: paymentIdRef.current
        });
    }

    const handleBack = async () => {
        back();

        await ReservationAPI.disable({
            ReservationId: reservationId
        });
    };

    const handleCreateOrderId = async () => {
        setPaymentDisavled(true);

        let payment = await PaymentAPI.create({
            ReservationId: reservationId
        });

        setPaymentId(payment.PaymentId);
        paymentIdRef.current = payment.PaymentId;

        return payment.OrderId;
    }

    const { steps, currentStepIndex, step, next, back } = useMultistepForm([
        <ReservationForm {...reservationData} updateFields={updateFields} next={handleNext} />,
        <PaymentForm createOrderId={handleCreateOrderId} onPaid={handlePaymentSuccess} onCancel={handlePaymentCancellation} onError={handlePaymentError} back={handleBack} disabled={paymentDisabled} />,
        <Review />
    ]);

    function updateFields(fields) {
        setReservationData((prev) => {
            return { ...prev, ...fields }
        })
    }

    return (
        <PayPalScriptProvider options={{
            "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
            "currency": 'EUR',
            "enable-funding": 'card'
        }}>
            <AppAppBar />
            <Container sx={{ flex: 1, pb: 10, display: "flex", alignItems: "center", flexDirection: 'column' }}>
                <Box sx={{ mt: 7 }}>
                    <Typography variant="h3" gutterBottom marked="center" align="center">
                        Prenotazione
                    </Typography>
                </Box>
                <Stepper activeStep={currentStepIndex} sx={{ pt: 3, pb: 5, maxWidth: 700, width: '100%' }}>
                    {stepsLabels.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>

                <Paper elevation={3} sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 6 }, maxWidth: 900, width: '100%' }}>
                    <React.Fragment >
                        {step}
                    </React.Fragment>
                </Paper>
            </Container>

            <AppFooter />
            <Snackbar
                open={validationMessageOpen}
                closeFunc={() => { setValidationMessageOpen(false); }}
                message={validationMessage}
            />
        </PayPalScriptProvider >
    );
}

export default withRoot(Reservation);
